import axios from 'axios';
import Cookies from 'universal-cookie';

// ----------------------------------------------------------------------

const axiosInstance = axios.create();
const cookies = new Cookies();

axiosInstance.defaults.xsrfCookieName = 'csrftoken';
axiosInstance.defaults.xsrfHeaderName = 'X-CSRFToken';
axiosInstance.defaults.withCredentials = true;
axiosInstance.defaults.headers = {
  'Content-Type': 'application/json',
  'X-CSRFToken': cookies.get('csrftoken')
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
