// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login')
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  livechat: {
    root: path(ROOTS_DASHBOARD, '/livechat')
    // new: path(ROOTS_DASHBOARD, '/chat/new'),
    // conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat')
    // new: path(ROOTS_DASHBOARD, '/chat/new'),
    // conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  }
};
