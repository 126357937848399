// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  livechat: getIcon('ic_chat'),
  chat: getIcon('ic_chat')
};

const sidebarConfig = [
  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      { title: 'livechat', path: PATH_DASHBOARD.livechat.root, icon: ICONS.chat },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat }
    ]
  }
];

export default sidebarConfig;
