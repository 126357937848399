import { createContext, useEffect, useState } from 'react';
import { Device } from 'twilio-client';
import PropTypes from 'prop-types';
import { useQuery, useQueryClient } from 'react-query';
import { getPhoneToken } from '../services/interactionService';
// utils
import axios from '../utils/axios';
import states from '../utils/states';

// ----------------------------------------------------------------------

const PhoneContext = createContext();

PhoneProvider.propTypes = {
  children: PropTypes.node
};

function PhoneProvider({ children }) {
  // const [state, setState] = useState();
  const [state, setState] = useState(states.CONNECTING);
  // const [number, setNumber] = useState('+16193243435');
  const [number, setNumber] = useState('');
  const [conn, setConn] = useState(null);
  const [device, setDevice] = useState(null);
  // const identity = '+18636940323';
  const identity = process.env.REACT_APP_API_BASE_URL;
  // const [token, setToken] = useState(null);
  const queryClient = useQueryClient();

  const { isIdle, data: token } = useQuery(['phoneToken'], getPhoneToken, {});

  if (conn) {
    conn.on('transportClose', () => {
      conn.disconnect();
      console.log('!!!!!!!Disconect!!!!!!!!!');
    });
  }

  const invalidatePhoneQueries = () => {
    console.log('__invalidate audio queries__');
    let queryKey = 'contacts';
    queryClient.invalidateQueries(queryKey);
    queryKey = 'interactions';
    queryClient.invalidateQueries(queryKey);
  };

  useEffect(() => {
    if (token) {
      const device = new Device();

      device.setup(token, {
        debug: true,
        codecPreferences: ['opus', 'pcmu'],
        fakeLocalDTMF: true,
        enableRingingState: true,
        allowIncomingWhileBusy: true
      });

      device.on('ready', () => {
        setDevice(device);
        setState(states.READY);
      });
      device.on('connect', (connection) => {
        console.log('Connect event');
        setConn(connection);
        setState(states.ON_CALL);
      });
      device.on('disconnect', () => {
        setState(states.READY);
        setConn(null);
      });
      device.on('incoming', (connection) => {
        setState(states.INCOMING);
        setConn(connection);
        connection.on('reject', () => {
          setState(states.READY);
          setConn(null);
          invalidatePhoneQueries();
        });
        invalidatePhoneQueries();
      });
      device.on('cancel', () => {
        setState(states.READY);
        setConn(null);
        invalidatePhoneQueries();
      });
      device.on('reject', () => {
        setState(states.READY);
        setConn(null);
        invalidatePhoneQueries();
      });

      return () => {
        device.destroy();
        setDevice(null);
        setState(states.OFFLINE);
      };
    }
  }, [token]);

  const handleCall = (toNumber) => {
    console.log('__beforeHandleCall__');
    device.connect({ To: toNumber, phoneNumber: toNumber });
    console.log('__afterHandleCall__');
  };

  // const handleCall = () => {
  //   console.log('__beforeHandleCall__');
  //   device.connect({ To: number, phoneNumber: number });
  //   console.log('__afterHandleCall__');
  // };

  const handleHangup = () => {
    console.log('__beforeHangup__');
    device.disconnectAll();
    invalidatePhoneQueries();
  };

  const handleAccept = () => {
    console.log('__beforeAccept');
    conn.accept();
    invalidatePhoneQueries();
  };

  const handleReject = () => {
    console.log('__beforeReject');
    conn.reject();
    invalidatePhoneQueries();
  };

  return (
    <PhoneContext.Provider
      value={{
        state,
        number,
        conn,
        device,
        token,
        identity,
        handleCall,
        handleHangup,
        handleAccept,
        handleReject,
        setNumber
      }}
    >
      {children}
    </PhoneContext.Provider>
  );
}

export { PhoneContext, PhoneProvider };
