import axios from '../utils/axios';

export async function getInteractions({ pageParam = 1, queryKey }) {
  const [key, senders] = queryKey;
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}interactions`, {
      params: {
        page: pageParam,
        sender__in: senders,
        receiver__in: senders
      }
    });
    const dataFromServer = response.data;
    const data = dataFromServer;
    data.next_page_number = data.next_page_number === null ? undefined : data.next_page_number;
    return data;
  } catch (error) {
    throw new Error('Problem fetching data');
  }
}

export async function getInteraction({ queryKey }) {
  const [key, id] = queryKey;
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}interactions/${id}`);
    const dataFromServer = response.data;
    const data = dataFromServer;
    return data;
  } catch (error) {
    throw new Error('Problem fetching data');
  }
}

export async function createInteraction(interactionData) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}interactions`, interactionData);
    const dataFromServer = response.data;
    const data = dataFromServer;
    return data;
  } catch (error) {
    throw new Error('Problem fetching data');
  }
}

export async function getPhoneToken() {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}interactions/token/call`);
    const dataFromServer = response.data;
    const { token } = dataFromServer;
    console.log('__phoneToken__');
    console.log(token);
    return token;
  } catch (error) {
    throw new Error('Problem fetching data');
  }
}
